import { Routes, Route } from "react-router-dom";
import LandingPage from "./pages/landing_page/index.js";
import ChatHistory from "./pages/chat_history.js";
import type { Node } from "react";
import 'gestalt/dist/gestalt.css';
import React from "react";

function App(): Node {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/chat_history/:thread_id" element={<ChatHistory />} />
      </Routes>
    </div>
  );
}

export default App;
