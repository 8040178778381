import React from 'react';
import type { Node } from 'react';


const LandingPage = ():Node => {
  return (
    <div className="landing-page">
      <h1 className="centered-text">Aeon Studio - We bring your fantasy to life</h1>
    </div>
  );
}

export default LandingPage;
