// @flow
import React, { useEffect, useState } from "react";
import type { Node } from "react";
import { useParams } from "react-router-dom";
import { Button, Box } from "gestalt"

const ChatHistory = (): Node => {
  const { thread_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [chatHistory, setChatHistory] = useState(null);
  const [beforeCursor, setBeforeCursor] = useState(null);
  const [afterCursor, setAfterCursor] = useState(null);
  const [selectedButton, setSelectedButton] = useState('top')
  // const base_url = `http://127.0.0.1:80/internal_tools/get_messages/${thread_id}`;
  const base_url = `https://aeonstudio.ai/internal_tools/get_messages/${thread_id}`;

  function updateChatHistory(data: any) {
    if (data) {
      setChatHistory(data);
      let messages = data.messages;
      let last_message = messages.at(-1);
      setBeforeCursor(messages[0].time)
      setAfterCursor(last_message.time)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (thread_id == null) {
        return;
      }
      try {
        const response = await fetch(`${base_url}`);
        const data = await response.json();
        updateChatHistory(data); // Assuming the API returns the data you want to display
      } catch (error) {
        console.error("Failed to fetch chat history", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [thread_id]);

  async function handleTopClick() {
    const url = `${base_url}`
    try {
      const response = await fetch(url);
      const data = await response.json();
      updateChatHistory(data); // Assuming the API returns the data you want to display
      setSelectedButton("top")
    } catch (error) {
      console.error("Failed to fetch chat history", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleBottomClick() {
    const url = `${base_url}`
    // const url = `https://aeonstudio.ai/internal_tools/get_messages/${thread_id}`;
    try {
      const response = await fetch(`${url}?cursor=${2147483647}&direction=before`);
      const data = await response.json();
      updateChatHistory(data); // Assuming the API returns the data you want to display
      setSelectedButton("bottom")
    } catch (error) {
      console.error("Failed to fetch chat history", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handlePreviousClick() {
    const url = `${base_url}?cursor=${beforeCursor}&direction=before`
    try {
      const response = await fetch(url);
      const data = await response.json();

      let updated_message_list = [...data.messages, ...chatHistory.messages];

      updateChatHistory({ messages: updated_message_list }); // Assuming the API returns the data you want to display
    } catch (error) {
      console.error("Failed to fetch chat history", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleNextClick() {
    const url = `${base_url}?cursor=${afterCursor}&direction=after`
    try {
      const response = await fetch(url);
      const data = await response.json();

      let updated_message_list = [...chatHistory.messages, ...data.messages];

      updateChatHistory({ messages: updated_message_list }); // Assuming the API returns the data you want to display
    } catch (error) {
      console.error("Failed to fetch chat history", error);
    } finally {
      setIsLoading(false);
    }
  }

  function _getColor(role: string, type: string) {
    if (role === "human") {
      return "black";
    } else {
      if (type === "text") {
        return "blue";
      } else {
        return "grey";
      }
    }
  }

  function _renderMessages() {
    let message_list_ui = []
    let current_date = null;
    let row_key = 0;
    chatHistory.messages.forEach((message, index) => {
      row_key++;
      let message_date = (new Date(message.time * 1000)).toLocaleDateString();
      if (current_date != message_date) {
        current_date = message_date;
        message_list_ui.push(
          <p
            style={{ color: "green" }}
            key={row_key}
          >
            {message_date}
          </p>
        )
        row_key++;
      }
      message_list_ui.push(
        <p
          style={{ color: _getColor(message.role, message.type) }}
          key={row_key}
        >
          {message.role}: {message.content}
        </p>
      )
    });

    return message_list_ui;
  }

  return (
    <div className="landing-page">
      <h1 className="centered-text">Chat history for: {thread_id}</h1>

      <Box paddingX={4} direction="row">
        <Button text="Top" selected={selectedButton == 'top'} onClick={handleTopClick} />
        <Button text="Bottom" selected={selectedButton == 'bottom'} onClick={handleBottomClick} />
      </Box>

      {/* Check if isLoading is true and display a loading message or spinner */}
      {isLoading ? (
        <p>Loading chat history...</p>
      ) : (
        // Render chat history if it is not null
        chatHistory && (
          <div style={{ paddingLeft: "20px" }}>
            {selectedButton == 'bottom' && <Box margin={"lgMargin"} display="flex" color="black" width={"100%"} direction="row" alignItems="center" justifyContent="center">
              <Button onClick={handlePreviousClick} text="Prev" />
            </Box>}
            {_renderMessages()}
            {selectedButton == "top" && <Box margin={"lgMargin"} display="flex" color="black" width={"100%"} direction="row" alignItems="center" justifyContent="center">
              <Button onClick={handleNextClick} text="Next" />
            </Box>}
          </div>
        )
      )}
    </div>
  );
};

export default ChatHistory;
